<template>
  <div class="login-container">
    <div class="login-box">
      <h1 class="login-title">Login</h1>
      <el-form @submit.prevent="handleSubmit" :model="form" :rules="rules" ref="loginForm">
        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email" @input="validateEmail" name="email"></el-input>
        </el-form-item>

        <el-form-item label="Password" prop="password">
          <el-input type="password" v-model="form.password" @input="validatePassword"></el-input>
          <span v-if="passwordError" class="error">{{ passwordError }}</span>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSubmit" :loading="isLoading">Login</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { config } from "@/helpers/apiConfig";
import axios from "axios";
import { enrichSentryUserData } from "@/sentry";
import LocalStorageManager from "@/localStorageManager";

export default {
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      emailError: "",
      passwordError: "",
      rules: {
        email: [
          { required: true, message: "Please input email", trigger: "blur" },
          { type: "email", message: "Invalid email address", trigger: ["blur", "change"] },
        ],
        password: [
          { required: true, message: "Please input password", trigger: "blur" },
          { min: 6, message: "Password must be at least 6 characters", trigger: "blur" },
        ],
      },
      isLoading: false,
    };
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailError = emailPattern.test(this.form.email) ? "" : "Invalid email address";
    },
    validatePassword() {
      this.passwordError =
        this.form.password.length >= 6 ? "" : "Password must be at least 6 characters";
    },
    async handleSubmit() {
      this.isLoading = true;
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          const data = await axios.post(config.root + "/api/auth0-login-custom", {
            email: this.form.email,
            password: this.form.password,
          });
          const { user, token } = data.data;
          this.$auth.accessToken = token.access_token;

          const tokenExpiry = new Date(Date.now() + token.expires_in * 1000);

          LocalStorageManager.setAuth0CustomLoginToken({
            token: token.access_token,
            expiry: tokenExpiry,
          });

          this.$auth.user = user;
          enrichSentryUserData(user);
          this.isLoading = false;
        } else {
          console.log("Form validation failed");
          this.isLoading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-box {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.error {
  color: red;
  font-size: 0.8rem;
}
</style>
