<template>
  <div class="livechat-container">
    <div class="livechat-container--top">
      <SearchChatFilters tab="queue" />
    </div>
    <div class="livechat-container--left" ref="sidebar">
      <div class="chat-list--header">
        <span>Live Chats</span>
        <el-button
          v-if="isNotRoutingAssignmentMode"
          type="success"
          style="float: right"
          :disabled="!isAgentOnline"
          @click.native="assignNewChat"
          size="mini"
          plain
        >
          Next Chat
          <i class="el-icon-arrow-right el-icon-right" />
        </el-button>
      </div>
      <div class="chat-list--wrapper">
        <div class="chat-list--main">
          <!-- Assigned chats -->
          <div class="chat-group-label">
            ON-GOING CHATS ({{ $store.state?.livechat?.ongoingChatCount }})
          </div>

          <QueueChatsList
            :chats-list="queueAssignedChatsArray"
            :selected-index.sync="selectedIndex"
            :closed.sync="closed"
            @on-init-fetch-interaction="onInitFetchInteraction"
          />

          <div
            v-loading="isLoadingMoreOngoingChats"
            element-loading-spinner="el-icon-loading"
            class="prev-conversation"
            @click="loadMoreOngoingChats"
            v-if="shouldShowMoreOngoingChats"
          >
            - show more -
          </div>

          <template
            v-if="
              isNotRoutingAssignmentMode ||
              $store.state.modules.handover.show_users_waiting_for_assignment
            "
          >
            <!-- Unassigned chats -->
            <div class="chat-group-label">
              ALL OTHER CHAT ({{ $store.state?.livechat?.queueChatCount }})
            </div>

            <QueueChatsList
              name="unassignedChatList"
              :chats-list="queueUnassignedChatsArray"
              :selected-index.sync="selectedIndex"
              :closed.sync="closed"
              @on-init-fetch-interaction="onInitFetchInteraction"
            />

            <div
              v-loading="isLoadingMoreChats"
              element-loading-spinner="el-icon-loading"
              class="prev-conversation"
              @click="loadMoreQueueChats"
              v-if="shouldShowMoreChats"
            >
              - show more -
            </div>
          </template>
        </div>
      </div>
      <div class="livechat-resizer" ref="resizer"></div>
    </div>
    <div v-if="selectedChat" class="livechat-container--right">
      <ChatsInteractions
        ref="interactionsPanel"
        :chats-list="queueChatsArray"
        :selected-chat="selectedRowKeyChat"
        :selected-index.sync="selectedIndex"
        :is-loading="fetchQueueChatLoading"
        :closed.sync="closed"
        @update-assigned="reFetchQueueChats"
      />
      <el-row v-if="$store.state.showAdvanced">
        <JSONEditor v-model="selectedChat" />
      </el-row>
    </div>
  </div>
</template>

<script>
import SearchChatFilters from "@/components/LiveChat/SearchChatFilters";
import QueueChatsList from "@/components/LiveChat/QueueChatsList";
import ChatsInteractions from "@/components/ChatInteractions/Index";
import JSONEditor from "@/components/JSONEditor";

import { doesChatBelongsToCurrentAgent } from "@/helperMethods/livechat/isAgentInChat";
import { getAvatar, getDisplayId } from "@/helperMethods/livechat/util";
import { simplifyMessage } from "@/helperMethods/livechat/simplifyMessage";
import { notifyWebsocketReconnected } from "@/helperMethods/notifyError";
import { pushNotification } from "@/helperMethods/pushNotification";
import { GRAPHQL_WEBSOCKET_STATE, listenGraphQLWebsocket } from "@/store/api";
import _ from "lodash";
import LocalStorageManager from "@/localStorageManager";
import { gql } from "@apollo/client/core";
import Vue from "vue";
import { mapGetters } from "vuex";
import { isFunction } from "@/helperMethods/util";
import { sendWebexPushNotification } from "@/helpers/webex";

export default {
  name: "ChatsTab",
  components: {
    SearchChatFilters,
    QueueChatsList,
    ChatsInteractions,
    JSONEditor,
  },

  data() {
    return {
      selectedTab: "queue",
      selectedIndex: 0,
      closed: false,
      type: { name: "queue", isSearchDateDisabled: true },
      isLoadingMoreChats: false,
      isLoadingMoreOngoingChats: false,
      isUpdatingQueue: false,
    };
  },
  mounted() {
    this.fetchQueueChats();
    this.fetchAgents();
    this.unsubcribleWSReconnectedEvent = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.RECONNECTED,
      () => {
        notifyWebsocketReconnected();
        this.fetchQueueChats();
      }
    );
    window.addEventListener("online", this.fetchQueueChats);
    const resizer = this.$refs.resizer;
    const sidebar = this.$refs.sidebar;
    this.$emit("initResizer", resizer, sidebar);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.fetchQueueChats);
    isFunction(this.unsubcribleWSReconnectedEvent) && this.unsubcribleWSReconnectedEvent();
  },
  computed: {
    ...mapGetters([
      "fetchQueueChatLoading",
      "queueChatsArray",
      "queueAssignedChatsArray",
      "queueUnassignedChatsArray",
      "selectedChatId",
      "getRoutingMode",
      "isAgentOnline",
      "isUsingResolvedFilter",
      "isUsingAbandonedFilter",
      "activeTabName",
    ]),
    selectedChat: {
      get() {
        return this.$store.getters.selectedChat;
      },
      set(selectedChat) {
        if (selectedChat && _.has(selectedChat, "RowKey")) {
          this.$store.commit("SET_SELECTED_CHAT_ID", selectedChat.RowKey);
        } else {
          this.$store.commit("SET_SELECTED_CHAT_ID", null);
        }
      },
    },

    isNotRoutingAssignmentMode() {
      return this.getRoutingMode !== "assignment";
    },

    selectedRowKeyChat() {
      if (_.isEmpty(this.selectedChatId)) {
        return null;
      }

      const selectedchat = this.selectedChat;

      const unreadMessageList = LocalStorageManager.getUnreadMessageList();
      const targetUnreadMessageChat = _.find(unreadMessageList, {
        chatId: this.selectedChatId,
      });
      if (targetUnreadMessageChat) {
        targetUnreadMessageChat.unreadMessage = 0;
      } else {
        unreadMessageList.push({
          chatId: this.selectedChatId,
          unreadMessage: 0,
        });
      }

      LocalStorageManager.setUnreadMessageList(unreadMessageList);

      const chatWithMessageSorted = Object.assign({}, selectedchat, {
        reply: "",
        unreadMessage: 0,
      });
      return chatWithMessageSorted;
    },
    isAdaptiveView() {
      return screen.width <= 1024;
    },
    shouldShowMoreChats() {
      return this.queueChatsArray.length < this.$store.state.livechat.queueChatCount;
    },
    shouldShowMoreOngoingChats() {
      return this.queueAssignedChatsArray.length < this.$store.state.livechat.ongoingChatCount;
    },
  },
  methods: {
    reFetchQueueChats() {
      this.fetchQueueChats();
    },
    async loadMoreQueueChats() {
      this.isLoadingMoreChats = true;

      const payload = {
        limit: this.queueAssignedChatsArray.length + 20,
      };
      await this.fetchQueueChats(payload);

      this.isLoadingMoreChats = false;
    },
    async loadMoreOngoingChats() {
      this.isLoadingMoreOngoingChats = true;

      const payload = {
        limit: this.queueUnassignedChatsArray.length + 20,
      };
      await this.fetchQueueChats(payload);

      this.isLoadingMoreOngoingChats = false;
    },
    getAvatar,
    //FIXME(Brian): reduce duplicated between several components
    notifyError(message) {
      this.$notify.error({
        title: "Error",
        message,
        position: "bottom-right",
      });
    },
    closeMobileChatView() {
      this.closed = false;
    },
    assignNewChat() {
      this.$apollo
        .query({
          query: gql`
            query {
              livechatAPI {
                getNextLivechat
              }
            }
          `,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const error = _.get(response.data, "livechatAPI.getNextLivechat.error");
          if (error) {
            this.notifyError(error);
          } else {
            const nextChat = _.get(response.data, "livechatAPI.getNextLivechat.nextLivechat");

            if (nextChat) {
              const selectedChatId = nextChat.RowKey;
              Vue.set(this.$store.state.livechat, "selectedChatId", selectedChatId);
              this.$store.commit("SELECT_LIVE_CHAT", {
                chatId: selectedChatId,
                type: "queue",
              });
              this.joinChat(nextChat);
            }
          }
        })
        .catch((err) => {
          this.notifyError(err);
        });
    },
    joinChat(chat) {
      this.fetchQueueChats().then(() => {
        this.selectedChat = chat;
        this.$nextTick(() => {
          this.$refs.interactionsPanel.joinLivechat(chat);
        });
      });
    },
    async _fetchQueueChats(payload = { limit: 20 }) {
      await this.$store.dispatch("FETCH_ALL_QUEUE_CHATS", payload);
    },
    fetchQueueChats: _.throttle(
      async function (payload = { limit: 20 }) {
        if (this.isUpdatingQueue) {
          return;
        }

        this.isUpdatingQueue = true;
        this._fetchQueueChats(payload).finally(() => {
          this.isUpdatingQueue = false;
        });
      },
      500,
      { leading: true, trailing: true }
    ),
    onInitFetchInteraction() {
      this.$nextTick(() => {
        this.$refs.interactionsPanel.showMoreConversation(this.selectedChat, true);
      });
    },
    // to update the count on agent panel and update agent status
    fetchAgents: _.throttle(
      function () {
        this.$store.dispatch("FETCH_AGENTS").then((data) => {
          const currentAgent = _.find(
            data,
            (agent) => agent.email === this.$store.state.profile.email
          );
          if (currentAgent) {
            this.$store.commit("SET_AGENT_STATUS", currentAgent.status);
          }
        });
      },
      1500,
      { leading: true }
    ),
  },
  apollo: {
    $subscribe: {
      livechatSessionLeave: {
        query: gql`
          subscription {
            livechatSessionLeave
          }
        `,
        result({ data }) {
          // When another agent leave the chat:
          // - Events: On escalation and normal chat leaving
          // - Result: On escalation new chat will be created
          // data = { previousChat, newChat }
          // Note: `newChat` only available for on escalation
          const updatedLivechat = _.get(data, "livechatSessionLeave");
          const previousChat = _.get(updatedLivechat, "previousChat");
          const newChat = _.get(updatedLivechat, "newChat");
          const ownAgentEmail = _.get(this.$store.state, "profile.email");

          // NOTE: if using REMOVE_QUEUE_CHAT removed in mem, after refresh still exist on normal leave
          // UPDATE_QUEUE_CHAT mean having duplicated chat session
          // Visibility in this case depends on chat.agents logic
          if (previousChat.RowKey) {
            // Case: Escalation - If the chat is escalated, remove it from queueChatObject for all agents in the same department.
            if (!previousChat.agents?.includes(ownAgentEmail) && newChat?.RowKey) {
              this.$store.commit("REMOVE_QUEUE_CHAT", { resolvedChatId: previousChat.RowKey });
            }
            // Case: Invite agent / leave chat - Update the chat in queueChatObject
            else
              this.$store.commit("UPDATE_QUEUE_CHAT", {
                updatedLivechat: previousChat,
              });
          }

          if (newChat?.RowKey) {
            this.$store.commit("ADD_QUEUE_CHAT", {
              newChat,
            });
          }

          // refetch queue chats to prevent out of sync
          this.fetchQueueChats();

          this.fetchAgents();
        },
      },
      livechatSessionUpdated: {
        query: gql`
          subscription {
            livechatSessionUpdated
          }
        `,
        result({ data }) {
          const updatedLivechat = _.get(data, "livechatSessionUpdated");
          const updatedChatAgentList = _.get(updatedLivechat, "agents", []);
          const oldChatAgentList = _.get(this.selectedchat, "agents", []);
          const agentJoinedChat = updatedChatAgentList.length > oldChatAgentList.length;

          const ownAgentEmail = _.get(this.$store.state, "profile.email");
          const joinedAgentNotSelf = !updatedChatAgentList.includes(ownAgentEmail);
          const selectedChatIsUpdated = this.selectedChatId === updatedLivechat.RowKey;
          if (agentJoinedChat && joinedAgentNotSelf && selectedChatIsUpdated) {
            // this.$store.commit("SET_SELECTED_CHAT_ID", "");

            const h = this.$createElement;
            const message = `Chat has been taken by ${updatedChatAgentList[0]}. Please select another chat.`;
            const finalizedMessage = h("i", { style: "color: teal" }, message);
            this.$notify.warning({
              title: "Chat is taken",
              message: finalizedMessage,
              position: "bottom-right",
            });

            // close selected chat if chat is already taken
            this.$store.commit("SET_SELECTED_CHAT_ID", null);
            this.$store.commit("SELECT_MONITOR_CHAT", {
              partitionKey: null,
              rowKey: null,
              type: "monitor",
            });
          }

          this.$store.commit("UPDATE_QUEUE_CHAT", {
            updatedLivechat,
          });

          this.fetchAgents();
          this.fetchQueueChats();
        },
      },
      livechatResolved: {
        query: gql`
          subscription {
            livechatResolved
          }
        `,
        result({ data }) {
          const resolvedChatId = _.get(data, "livechatResolved.chatId");
          const resolvedChat = _.get(data, "livechatResolved");
          const agentProfileEmail = _.get(this.$store.state, "profile.email");
          const isAgentInvolvedChat = _.includes(resolvedChat.chat.agents, agentProfileEmail);
          // to close mobile and display queue
          this.closeMobileChatView();

          if (isAgentInvolvedChat) {
            this.$notify.success({
              title: "Success",
              message: `Chat ${resolvedChatId} has been resolved, you can still view it under the 'Resolved' tab.`,
              position: "bottom-right",
            });
          }

          const resolvedChatisSelected = this.selectedChatId === resolvedChatId;
          if (resolvedChatisSelected) {
            this.$store.commit("SET_SELECTED_CHAT_ID", null);
          }

          const isChatAttendedByAgent =
            resolvedChat.chat.agents && resolvedChat.chat.agents.length > 0;

          if (isChatAttendedByAgent) {
            this.$store.commit("INCREMENT_RESOLVED_CHAT_TOTAL_COUNT");

            const isOnResolvedTab = this.activeTabName === "resolved";
            const shouldAddToResolvedChat = isOnResolvedTab && !this.isUsingResolvedFilter;
            if (shouldAddToResolvedChat) {
              this.$store.commit("ADD_RESOLVED_CHAT", {
                resolvedChat,
              });
            }
          } else {
            this.$store.commit("INCREMENT_ABANDONED_CHAT_TOTAL_COUNT");

            const isOnAbandonedTab = this.activeTabName === "abandoned";
            const shouldAddToAbandonedChat = isOnAbandonedTab && !this.isUsingAbandonedFilter;
            if (shouldAddToAbandonedChat) {
              this.$store.commit("ADD_ABANDONED_CHAT", {
                abandonedChat: resolvedChat,
              });
            }
          }
          LocalStorageManager.removeUnreadRecordUsingChatId(resolvedChatId);
          this.$store.commit("REMOVE_QUEUE_CHAT", { resolvedChatId });

          this.fetchAgents();
          this.fetchQueueChats();
        },
      },
      livechatJoinQueue: {
        query: gql`
          subscription {
            newChat: livechatJoinQueue
          }
        `,
        async result({ data }) {
          const newChat = _.get(data, "newChat");
          const email = this.$store.state.profile.email;
          const isInsideOrAssignedChat = _.get(newChat, "agents", []).includes(email);

          newChat.typingIndicatorStatus = false;
          newChat.unreadMessage = 0;
          this.$store.commit("ADD_QUEUE_CHAT", { newChat });

          if (isInsideOrAssignedChat) {
            const transferRedacted = _.get(newChat, "transferRedacted", false);
            const transferRedactedId = transferRedacted ? newChat.RowKey : null;
            const interactions = transferRedacted ? _.get(newChat, "interactions", []) : [];
            await this.fetchQueueChats({
              transferRedactedId,
              transferRedactedIdInteractions: interactions,
              limit: 20,
            });
          } else {
            await this.fetchQueueChats();
          }

          // Filtered agents by department still receive chat from server
          // Check department to show notification
          const isCheckingWithDepartment = _.get(
            this,
            "this.$store.state.modules.handover.handoverRouting.byDepartment",
            false
          );
          let isAgentEligibleForLivechatDepartment = true;
          if (isCheckingWithDepartment && newChat.department) {
            const departments = _.get(this, "$store.state.department.departments", []);
            const agentDepartments = _.get(this, "$store.state.profile.departments", []);
            const departmentOfLivechat = _.find(
              departments,
              (department) => department.id === newChat.department
            );
            isAgentEligibleForLivechatDepartment =
              departmentOfLivechat && _.indexOf(agentDepartments, departmentOfLivechat.name) !== -1;
          }

          const isBroadcastRoutingMode = this.getRoutingMode === "broadcast";
          if (
            (isInsideOrAssignedChat || isBroadcastRoutingMode) &&
            isAgentEligibleForLivechatDepartment
          ) {
            const displayIdPayload = {
              ...newChat.stateVariables,
              chatId: newChat.RowKey,
              user_id: newChat.user_id,
            };
            const displayId = getDisplayId(displayIdPayload);

            const pushNotifMessage = {
              title: displayId + " joined chat",
              icon: "./img/icons/mstile-150x150.png",
            };
            pushNotification(pushNotifMessage, 5000, () => {
              window.focus();
            });

            if (_.get(this.$store, "state.modules.webex.enable", false)) {
              sendWebexPushNotification("New Chat", displayId + " joined chat");
            }
          }

          this.fetchAgents();
        },
      },
      livechatTypingIndicator: {
        query: gql`
          subscription {
            livechatTypingIndicatorStatus
          }
        `,
        // Result hook
        result({ data }) {
          setTimeout(() => {
            const { user_id, status: isTyping } = _.get(data, "livechatTypingIndicatorStatus", {
              user_id: "",
              isTyping: false,
            });
            this.$store.dispatch("SET_TYPING_INDICATOR_FOR_CHAT", {
              user_id,
              isTyping,
            });
          });
        },
      },
      livechatAcceptedInvitation: {
        query: gql`
          subscription {
            chatInvite: livechatAcceptedInvitation
          }
        `,
        result({ data }) {
          const chatInvite = _.get(data, "chatInvite");
          const targetChat = this.queueChatsArray.find((chat) => chat.RowKey === chatInvite.RowKey);
          if (targetChat) {
            this.$store.commit("UPDATE_QUEUE_CHAT", {
              updatedLivechat: chatInvite,
            });
          } else {
            this.$store.commit("ADD_QUEUE_CHAT", { newChat: chatInvite });
          }

          this.fetchAgents();
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";
@import "./styles/tab.scss";

.chat-group-label {
  color: $color-lightgrey;
  font-size: 10px;
  margin: 10px 0;
}

.prev-conversation {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  margin: 0 auto 15px;
  font-size: 12px;
  cursor: pointer;
}
</style>
