<template>
  <section>
    <table class="el-table">
      <!-- Headers -->
      <tr>
        <th class="cell" rowspan="1" colspan="!" />
        <th
          v-for="role in $store.state.modules['access_control'].roles"
          :key="role.name"
          class="cell keyreply-delete-cell"
        >
          {{ role.name }}
          <el-button
            v-if="role.name !== 'admin'"
            type="text"
            size="mini"
            style="margin-left: 5px"
            @click="deleteRole(role)"
          >
            <i class="el-icon-delete" />
          </el-button>
        </th>
        <th>
          <el-popover v-model="showNewRolePopover">
            <el-input v-model="newRoleInput" @keydown.enter.native="createRole()">
              <template slot="prepend">Role name</template>
              <el-button slot="append" icon="el-icon-check" @click="createRole()" />
            </el-input>
            <el-tooltip slot="reference" placement="top" content="Add new role">
              <el-button size="small" style="margin-left: 5px">
                <i class="el-icon-plus" />
              </el-button>
            </el-tooltip>
          </el-popover>
        </th>
      </tr>
      <!-- Select all -->
      <tr>
        <td>Modules</td>
        <td
          v-for="role in $store.state.modules['access_control'].roles"
          :key="role.name"
          class="cell"
        >
          <el-checkbox :checked="role.name === 'admin'" @change="selectAllModules(role, $event)" />
        </td>
      </tr>
      <!-- Values -->
      <tr v-for="(module, rowIndex) in sortedModules" :key="module">
        <td class="cell" style="min-width: 160px">{{ module }}</td>
        <td
          v-for="role in $store.state.modules['access_control'].roles"
          :key="role.name"
          class="cell"
        >
          <el-checkbox
            v-model="role.access[modules[rowIndex]]"
            :disabled="role.name === 'admin' && module === 'access_control'"
          />
        </td>
      </tr>
    </table>

    <br />

    <el-button
      style="width: 100%"
      type="primary"
      :icon="buttonIcon"
      :disabled="isUpdatingRoles"
      @click="persistRoles"
    >
      Save changes
    </el-button>
  </section>
</template>

<script>
import { OUT_REACH_MODULE } from "@/helperMethods/outreach";
import * as jsondiffpatch from "jsondiffpatch/dist/jsondiffpatch.umd";
import { mapChanges } from "@/helperMethods/modules";

export default {
  props: [],
  data() {
    return {
      originalModules: {},
      isUpdatingRoles: false,
      showNewRolePopover: false,
      newRoleInput: null,
      modules: [
        "access_control",
        "authorize_widget",
        OUT_REACH_MODULE,
        "outreach-admin",
        "agents",
        "analytics",
        "audit",
        "broadcast",
        "documentation",
        "editor_approve",
        "webpage_checker",
        "editor",
        "evaluation",
        "faq",
        "faq2",
        "faqw",
        "games",
        "import_export",
        "labs",
        "entities",
        "livechat",
        "file-storage",
        "chat_form",
        "prism",
        "settings",
        "subscription",
        "users",
        "visitors",
        "webchat",
        "whatsapp",
        "whatsapp_checker",
        "supervisor",
        "clone_user",
        "ecare",
        "customer_care",
        "developer",
        "ecare",
        "customer_care",
        "knowledge_base",
        "genai",
      ],
    };
  },
  mounted() {
    this.saveOriginalModules();
  },
  methods: {
    mapChanges,
    saveOriginalModules() {
      this.originalModules = _.cloneDeep(this.modulesMaster);
    },
    createRole() {
      if (!this.newRoleInput || !this.newRoleInput.trim()) {
        return;
      }
      const newRole = {
        name: this.newRoleInput,
        access: {
          subscription: false,
          faq: false,
          editor: false,
          editor_approve: false,
          webpage_checker: false,
          analytics: false,
          webchat: false,
          livechat: false,
          games: false,
          users: false,
          prism: false,
          whatsapp: false,
          whatsapp_checker: false,
          broadcast: false,
          labs: false,
          audit: false,
          documentation: false,
          access_control: false,
        },
      };
      this.$store.state.modules["access_control"].roles.push(newRole);
      this.newRoleInput = "";
      this.showNewRolePopover = false;

      this.$confirm("New role added. Save the change?", "Save changes", {
        confirmButtonText: "Save",
        cancelButtonText: "Not now",
        type: "success",
      })
        .then(() => {
          this.persistRoles();
        })
        .catch(() => {});
    },
    deleteRole(role) {
      this.$confirm(`This will delete ${role.name} role. Continue?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(() => {
          const roles = this.$store.state.modules["access_control"].roles;
          this.$store.state.modules["access_control"].roles = _.remove(
            roles,
            (r) => r.name !== role.name
          );
          this.$confirm("Save the change?", "Save changes", {
            confirmButtonText: "Save",
            cancelButtonText: "Not now",
            type: "info",
          })
            .then(() => {
              this.persistRoles();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    selectAllModules(role, event) {
      _.map(this.$store.state.modules["access_control"].roles, (r) => {
        if (r === role) {
          _.map(this.modules, (module) => {
            if (module !== "access_control") {
              role.access[module] = event;
            }
          });
        }
      });
    },
    addRolesLoadCount(value) {
      this.rolesLoadCount += value;
    },
    persistRoles() {
      this.isUpdatingRoles = true;
      const changes = jsondiffpatch.diff(this.originalModules, this.modulesMaster);
      this.mapChanges(changes);
      this.$store
        .dispatch("SAVE_MODULES", {
          modules: changes,
        })
        .then(
          () => {
            this.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: "Config Saved",
            });
            this.saveOriginalModules();
          },
          () => {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: "Error saving config",
            });
          }
        )
        .then(() => {
          this.isUpdatingRoles = false;
        });
    },
  },
  computed: {
    buttonIcon() {
      return this.isUpdatingRoles ? "el-icon-loading" : "el-icon-upload2";
    },
    modulesMaster: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
    sortedModules() {
      return this.modules.sort((a, b) => a.localeCompare(b));
    },
  },
};
</script>

<style scoped>
.keyreply-delete-cell button {
  visibility: hidden;
}

.keyreply-delete-cell:hover button {
  visibility: visible;
}
</style>
