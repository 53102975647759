<template>
  <div v-loading="isLoading">
    <div v-if="!showSemanticMemoryActions" id="knowledge-base-wrapper">
      <div id="kb-header">
        <div v-if="isSemanticSearchMode" class="main-title">Search Result</div>
        <div v-else-if="isSemanticAskMode" class="main-title">Ask Result</div>
        <div v-else class="main-title">Documents</div>

        <div class="buttons">
          <el-input
            v-model="search"
            placeholder="Search documents,tags..."
            prefix-icon="el-icon-search"
            clearable
            @clear="handleClearSearch"
            @input="handleSearch"
          ></el-input>
          <el-button @click="handleSemanticMemoryAction" type="warning" size="large"
            >Ask/Search KB</el-button
          >
          <el-button
            v-if="!semanticMode"
            type="primary"
            size="large"
            icon="el-icon-upload"
            @click="openUploadDialog"
            >Upload knowledge</el-button
          >
          <el-button v-else type="plain" size="large" @click="backToKnowledgeBase"
            >Back to Knowledge Base</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <!-- Import/Export -->
          <div style="display: flex; gap: 0px; margin-left: 0px">
            <el-button
              type="primary"
              size="large"
              icon="el-icon-download"
              @click="openExportDialog"
              style="margin-left: 0px"
              :disabled="genai_documents.length === 0"
              >Export</el-button
            >
            <el-button
              type="primary"
              size="large"
              icon="el-icon-upload"
              @click="openImportDialog"
              style="margin-left: 5px"
              >Import</el-button
            >
          </div>
        </div>
      </div>
      <div id="kb-body">
        <div class="kb-query-result" v-if="isSemanticAskMode">
          <div style="font-size: 18px; margin-bottom: 15px; color: #1d57d8">
            <b>Query: {{ genai_query_result.question }}</b>
          </div>
          <div class="answer">
            <pre style="color: #525457; font-size: 14px">{{ genai_query_result.text }}</pre>
          </div>
        </div>
        <div class="main-title" v-if="isSemanticAskMode" style="margin-bottom: 10px">
          Relevant Sources
        </div>
        <div class="kb-documents" v-loading="isGettingUploadedDocuments">
          <div class="filters">
            <el-tabs v-model="selectedFilter" @tab-click="handleFilterChange">
              <el-tab-pane
                v-for="(type, index) in documentTypeNames"
                :key="index"
                :label="type.label.toLocaleUpperCase()"
                :name="type.name"
              ></el-tab-pane>
            </el-tabs>
          </div>
          <div v-if="!isGenAIDocumentEmpty" class="documents-wrapper">
            <div class="document" v-for="(item, index) in genai_documents" :key="index">
              <div class="main-content">
                <div class="left-container">
                  <div class="folder">
                    <img
                      :src="fileIconMapping(item.sourceContentType || item.document_type)"
                      alt=""
                      style="width: 40%"
                    />
                  </div>
                  <div class="detail">
                    <div class="title">
                      {{ getTableDocumentName(item) }}
                    </div>
                    <div class="type">
                      <span style="font-weight: 500">Type:</span>
                      {{ item.sourceContentType || item.document_type }}
                    </div>
                    <div class="uploaded-by">
                      <span style="font-weight: 500">Uploaded By: </span>
                      {{ item.uploadedBy || "N/A" }}
                    </div>
                    <div class="uploaded-at">
                      <span style="font-weight: 500">Uploaded At: </span>
                      {{ formattedDate(item.updatedAt) }}
                    </div>
                    <div class="tag" v-if="Object.keys(item.tags).length > 0">
                      <div>
                        <span style="font-weight: 500">Tags: </span>
                        <div v-for="(tags, name) in item.tags" :key="name">
                          <div v-if="!startsWithUnderscore(name)">
                            <span style="font-size: 14px; margin-left: 10px">{{ name }} </span>
                            <el-tag
                              style="margin-left: 10px"
                              v-for="(tag, index) in tags"
                              :key="index"
                              size="small"
                              >{{ tag }}
                            </el-tag>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tag" v-else>
                      <span> <span style="font-weight: 500">Tags:</span> None </span>
                    </div>
                    <el-button
                      v-if="!semanticMode"
                      type="primary"
                      size="mini"
                      @click="openTagEditor(item)"
                      >Edit Tags</el-button
                    >
                  </div>
                </div>
                <div class="buttons">
                  <el-button
                    v-if="!semanticMode"
                    type="primary"
                    size="small"
                    style="margin-right: 10px"
                    @click="openStatusDialog(item)"
                    :loading="isFetchDocumentStatus"
                    :disabled="isFetchDocumentStatus"
                    >Status</el-button
                  >
                  <el-button
                    v-if="semanticMode"
                    @click="toggleExpandData(index)"
                    type="primary"
                    size="small"
                    style="margin-right: 10px"
                    icon="el-icon-more"
                    >Details</el-button
                  >
                  <el-button
                    v-if="!semanticMode && isAbleToEdit(item)"
                    @click="handleDeleteDocument(item.document_id)"
                    type="danger"
                    icon="el-icon-delete"
                    size="small"
                    style="margin-right: 20px"
                    >Delete</el-button
                  >
                </div>
              </div>
              <div class="expand-content" style="display: none">
                <el-table :data="item.partitions" style="width: 100%">
                  <el-table-column type="index" label="ID" width="120"> </el-table-column>
                  <el-table-column label="Text">
                    <template slot-scope="scope">
                      <p v-html="renderedText(scope.row.text)"></p>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div v-if="!inSearchMode">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="10"
                @current-change="handleCurrentPageChange"
                :total="paginationCount"
                :current-page.sync="currentPage"
              >
              </el-pagination>
            </div>
          </div>
          <div v-else class="empty">
            <div>
              <img :src="EmptyIcon" alt="" style="width: 200px" />
              <p>You don't have any document, please upload them initially.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div>
        <el-button @click="backToKnowledgeBase">Back to Knowledge Base</el-button>
      </div>
      <div class="kb-empty">
        <div class="kb-empty-wrapper">
          <div class="filter-box" v-if="settings_tags.length > 0">
            <div class="main-title">Filter</div>
            <p style="font-size: 14px; margin-top: 5px">
              Select tags to filter your Knowledge Base
            </p>
            <div class="filter-select-box">
              <div v-for="(item, index) in settings_tags" :key="index">
                <div>
                  <p style="font-weight: 500; margin: 10px 0">
                    {{ item.name.toLocaleUpperCase() }}
                  </p>
                  <div>
                    <el-select
                      placeholder="Select tags..."
                      multiple
                      v-model="semanticFilters[item.name]"
                    >
                      <el-option
                        v-for="(tag, index) in item.tags"
                        :key="index"
                        :label="tag"
                        :value="tag"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ask-box">
            <div class="ask-box-body" style="overflow-y: scroll">
              <transition-group name="fade" tag="div" class="messages-container">
                <div v-for="message in messagesArr" :key="message.id">
                  <div
                    v-if="message.type === 'user'"
                    class="user-message"
                    style="font-size: 18px; margin-bottom: 15px; color: #1d57d8"
                  >
                    <b>{{ message.text }}</b>
                  </div>
                  <div
                    v-else
                    class="answer-message answer"
                    style="
                      font-size: 14px;
                      color: #525457;
                      word-break: break-word;
                      white-space: pre-wrap;
                      margin-bottom: 15px;
                    "
                  >
                    <div>{{ message.text }}</div>
                    <div
                      style="margin-top: 10px; font-size: 12px"
                      v-if="message.relevantSources && message.relevantSources?.length > 0"
                    >
                      <b>{{ message.question ? "Relevant Sources: " : "Search results: " }}</b>
                      <span
                        :loading="textLoading"
                        v-for="(source, index) in message.relevantSources"
                        :key="index"
                      >
                        <el-tag style="margin-left: 5px; margin-bottom: 5px"
                          >{{ source.sourceName }}
                        </el-tag>
                      </span>
                      <el-tooltip content="More details" v-if="message.relevantSources?.length > 0">
                        <el-button
                          style="margin-left: 15px"
                          icon="el-icon-more"
                          circle
                          size="small"
                          @click="showMoreDetails(message)"
                        ></el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </transition-group>

              <TypingIndicator v-if="typingIndicatorStatus" />
            </div>
            <div class="ask-box-footer">
              <div
                style="
                  display: flex;
                  align-items: center;
                  box-sizing: border-box;
                  padding: 10px;
                  height: 100%;
                "
              >
                <el-input
                  :disabled="typingIndicatorStatus"
                  v-model="semanticQuery"
                  placeholder="Type something here..."
                  @keyup.enter.native="handleSemanticMemoryAsk"
                ></el-input>
                <div style="display: flex; margin-left: 5px">
                  <el-button @click="handleSemanticMemoryAsk" style="width: 50%" type="primary"
                    >ASK</el-button
                  >
                  <el-button
                    @click="handleSemanticMemorySearch"
                    style="width: 50%; margin-left: 5px"
                    type="warning"
                    >SEARCH</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UploadModalVue
      v-if="uploadDialogVisible"
      @updateDialogVisible="updateDialogVisible"
      :uploadDialogVisible="uploadDialogVisible"
      :genai_settings="settings"
      :currentFilter="selectedFilter"
    />
    <DocumentStatusModal
      v-if="statusDialogVisible"
      :statusDialogVisible="statusDialogVisible"
      @updateStatusDialogVisible="updateStatusDialogVisible"
      @refreshDocumentStatus="refreshDocumentStatus"
      :selectedDocumentStatus="selectedDocumentStatus"
      :isRefreshingStatus="isRefreshingStatus"
      :selectedDocument="selectedDocument"
    />

    <TagEditor
      v-if="tagEditorVisible"
      :tagEditorVisible="tagEditorVisible"
      :selectedDocument="selectedDocument"
      :isLoading="isLoadingEditTag"
      @updateTagEditorVisible="updateTagEditorVisible"
      @handleUpdateDocumentTag="handleUpdateDocumentTag"
    />
    
    <ExportDialog
      v-if="exportDialogVisible"
      @updateDialogVisible="updateExportDialogVisible"
      @export="handleExport"
      :isLoading="isLoadingExportImport"
      :dialogVisible="exportDialogVisible"
    />

    <ImportDialog
      v-if="importDialogVisible"
      @updateDialogVisible="updateImportDialogVisible"
      @import="handleImport"
      :isLoading="isLoadingExportImport"
      :dialogVisible="importDialogVisible"
    />
  </div>
</template>

<script>
import UploadModalVue from "./UploadModal.vue";
import DocumentStatusModal from "./DocumentStatusModal.vue";
import TagEditor from "./TagEditor.vue";
import TypingIndicator from "./TypingIndicator.vue";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { mdToHtml } from "@/helpers/markdown";
import { mapGetters } from "vuex";
import moment from "moment";
import ExportDialog from "./ExportDialog.vue";
import ImportDialog from "./ImportDialog.vue";

export default {
  components: {
    UploadModalVue,
    DocumentStatusModal,
    TypingIndicator,
    TagEditor,
    ExportDialog,
    ImportDialog,
  },
  data() {
    return {
      selectedSourceId: 1,
      selectedFilter: "all",
      documentTypeNames: [
        { name: "all", label: "All" },
        { name: "pdf", label: "PDF" },
        { name: "ppt", label: "Presentation" },
        { name: "word", label: "Doc" },
        { name: "text", label: "Text" },
      ],
      uploadDialogVisible: false,
      statusDialogVisible: false,
      semanticQuery: "",
      semanticFilters: {},
      isLoading: false,
      showSemanticMemoryActions: false,
      semanticMode: false,
      selectedDocumentStatus: null,
      isFetchDocumentStatus: false,
      isRefreshingStatus: false,
      currentPage: 1,
      messagesArr: [],
      typingIndicatorStatus: false,
      PdfIcon: require("@/views/KnowledgeBase/assets/icons/pdf.png"),
      TxtIcon: require("@/views/KnowledgeBase/assets/icons/txt.png"),
      DocsIcon: require("@/views/KnowledgeBase/assets/icons/doc.png"),
      VideoIcon: require("@/views/KnowledgeBase/assets/icons/video.png"),
      EmptyIcon: require("@/views/KnowledgeBase/assets/icons/empty.png"),
      PptIcon: require("@/views/KnowledgeBase/assets/icons/ppt.png"),
      AudioIcon: require("@/views/KnowledgeBase/assets/icons/audio.png"),
      selectedDocument: null,
      textLoading: false,
      search: "",
      inSearchMode: false,
      allowEditRoles: ["admin", "genai-editor"],
      tagEditorVisible: false,
      isLoadingEditTag: false,
      exportDialogVisible: false,
      isLoadingExportImport: false,
      importDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["getUserAuthentication"]),
    userProfile() {
      return this.$store.state.profile;
    },
    settings() {
      return this.$store.state.modules.genai;
    },
    settings_tags() {
      return this.settings.knowledge_document?.tags || [];
    },
    source() {
      return this.$store.state.genai.sources[this.selectedSourceId];
    },
    sources() {
      return this.$store.state.genai.sources;
    },
    genai_query_result() {
      return this.$store.state.genai.genai_query_result;
    },
    genai_query_documents() {
      return this.$store.state.genai.genai_query_documents;
    },
    isGenAIDocumentsEmpty() {
      return _.isEmpty(this.genai_query_documents);
    },
    genai_uploaded_documents() {
      return this.$store.state.genai.genai_uploaded_documents;
    },
    genai_documents() {
      if (this.genai_query_documents.length > 0) {
        this.semanticMode = true;
        const queryDocuments = this.getQueryDocuments();
        return queryDocuments;
      } else {
        this.semanticMode = false;
        const documents = _.cloneDeep(this.genai_uploaded_documents?.rows);
        if (documents) {
          documents.forEach((document) => {
            if (document.tags) {
              document.tags = JSON.parse(document.tags);
            } else {
              document.tags = [];
            }
          });
        }
        return documents;
      }
    },
    isGenAIDocumentEmpty() {
      return _.isEmpty(this.genai_documents);
    },
    isGettingUploadedDocuments() {
      return this.$store.state.genai.isGettingDocuments;
    },
    isSemanticSearchMode() {
      return this.semanticMode && _.has(this.genai_query_result, "query");
    },
    isSemanticAskMode() {
      return this.semanticMode && _.has(this.genai_query_result, "question");
    },
    semanticFiltersQuery() {
      const filters = _.cloneDeep(this.semanticFilters);
      for (const [key, value] of Object.entries(this.semanticFilters)) {
        if (value.length === 0) {
          this.$delete(filters, key);
        }
      }
      return filters;
    },
    paginationCount() {
      if (this.semanticMode) {
        return this.filteredQueryDocument.length;
      } else {
        return this.genai_uploaded_documents.count;
      }
    },
    filteredQueryDocument() {
      if (this.selectedFilter === "all") {
        return this.genai_query_documents;
      }
      return this.genai_query_documents.filter((item) =>
        item.sourceContentType.includes(this.selectedFilter)
      );
    },
  },
  methods: {
    selectSource(key) {
      this.selectedSourceId = Number(key);
      // this.$store.dispatch("GENAI_SELECT_MODULE", Number(key));
    },
    selectFunction(key) {
      // this.$store.dispatch("GENAI_SELECT_FUNCTION", Number(key));
    },
    toggleExpandData(index) {
      const expandData = document.querySelectorAll(".expand-content");
      const expandDataItem = expandData[index];

      if (expandDataItem.style.display === "none") {
        expandDataItem.style.display = "block";
      } else {
        expandDataItem.style.display = "none";
      }
    },
    openUploadDialog() {
      this.uploadDialogVisible = true;
    },
    async openStatusDialog(document) {
      try {
        this.isLoading = true;
        const documentStatus = await this.$store.dispatch("GENAI_GET_DOCUMENT_STATUS", {
          documentId: document.document_id,
        });
        this.selectedDocumentStatus = documentStatus;
        this.selectedDocument = document;
        this.statusDialogVisible = true;
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          title: "Error",
          message: "There was an error getting the document status",
          type: "error",
        });
        this.isLoading = false;
      }
    },
    async refreshDocumentStatus(documentId) {
      this.isRefreshingStatus = true;
      const documentStatus = await this.$store.dispatch("GENAI_GET_DOCUMENT_STATUS", {
        documentId,
      });
      this.selectedDocumentStatus = documentStatus;
      this.isRefreshingStatus = false;
      return documentStatus;
    },
    updateDialogVisible(value, forceClose = false) {
      if (forceClose) {
        this.uploadDialogVisible = false;
        this.currentPage = 1;
        return;
      }
      this.$confirm("Are you sure to cancel the upload", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.uploadDialogVisible = value;
        })
        .catch(() => {});
    },
    updateStatusDialogVisible(value) {
      this.statusDialogVisible = value;
    },
    async handleSemanticMemorySearch() {
      const query = this.semanticQuery;
      this.pushNewMessage(this.semanticQuery, "user");
      this.typingIndicatorStatus = true;

      const result = await this.$store.dispatch("GENAI_SEMANTIC_SEARCH", {
        query,
        filter: this.semanticFiltersQuery,
      });

      this.pushNewMessage(result.text, "bot", result.results, "search", result.query);
      this.typingIndicatorStatus = false;
    },
    async handleSemanticMemoryAsk() {
      const query = this.semanticQuery;
      if (query) {
        try {
          this.pushNewMessage(this.semanticQuery, "user");
          this.typingIndicatorStatus = true;
          const result = await this.$store.dispatch("GENAI_SEMANTIC_ASK", {
            query,
            filter: this.semanticFiltersQuery,
          });

          this.pushNewMessage(result.text, "bot", result.relevantSources, "ask", result.question);
          this.typingIndicatorStatus = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$el.querySelector(".ask-box-body");
        container.scrollTop = container.scrollHeight;
      });
    },
    pushNewMessage(text, type, relevantSources, actionType, question) {
      const newMessage = {
        text,
        id: uuid(),
        relevantSources,
        type,
      };

      if (actionType === "ask") {
        newMessage.question = question;
      } else if (actionType === "search") {
        newMessage.query = question;
      }
      this.semanticQuery = "";
      this.messagesArr.push(newMessage);
      this.scrollToBottom();
    },
    handleSemanticMemoryAction() {
      this.showSemanticMemoryActions = true;
    },
    backToKnowledgeBase() {
      this.semanticMode = false;
      this.$store.commit("GENAI_SET_QUERY_RESULT", null);
      this.$store.commit("GENAI_SET_DOCUMENTS", []);
      this.showSemanticMemoryActions = false;
      const expandData = document.querySelectorAll(".expand-content");
      expandData.forEach((item) => {
        item.style.display = "none";
      });
    },
    fileIconMapping(fileType) {
      const type = fileType.toLowerCase();
      if (type.includes("pdf")) {
        return this.PdfIcon;
      } else if (type.includes("word")) {
        return this.DocsIcon;
      } else if (type.includes("txt")) {
        return this.TxtIcon;
      } else if (type.includes("video")) {
        return this.VideoIcon;
      } else if (type.includes("ppt")) {
        return this.PptIcon;
      } else if (type.includes("audio")) {
        return this.AudioIcon;
      } else {
        return this.TxtIcon;
      }
    },
    async handleDeleteDocument(documentId) {
      try {
        this.$confirm("Are you sure to delete this document?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(async () => {
          await this.$store.dispatch("GENAI_DELETE_DOCUMENT", {
            documentId,
          });
          await this.$store.dispatch("GENAI_GET_UPLOADED_DOCUMENTS", {
            page: 1,
            type: this.selectedFilter,
          });
          this.$notify({
            title: "Success",
            message: "Document deleted successfully",
            type: "success",
          });
        });
      } catch (error) {
        this.$notify({
          title: "Error",
          message: "There was an error deleting the document",
          type: "error",
        });
      }
    },
    async handleCurrentPageChange(page) {
      if (this.semanticMode) {
        this.currentPage = page;
        return;
      }
      const selectedFilter = this.selectedFilter;
      await this.$store.dispatch("GENAI_GET_UPLOADED_DOCUMENTS", { page, type: selectedFilter });
    },
    async handleFilterChange() {
      const selectedFilter = this.selectedFilter;
      this.currentPage = 1;
      if (this.semanticMode) {
        return;
      }
      await this.$store.dispatch("GENAI_GET_UPLOADED_DOCUMENTS", { type: selectedFilter });
    },
    getQueryDocuments() {
      const documents = _.cloneDeep(this.filteredQueryDocument);
      const offset = (this.currentPage - 1) * 5;
      const limit = 5;
      return documents.slice(offset, offset + limit);
    },
    showMoreDetails(message) {
      const relevantSources = _.cloneDeep(message.relevantSources);
      const newRelevantSources = relevantSources.map((item) => {
        const tags = item.partitions[0]?.tags || {};
        return {
          ...item,
          tags,
        };
      });
      this.$store.commit("GENAI_SET_QUERY_RESULT", message);
      this.$store.commit("GENAI_SET_DOCUMENTS", newRelevantSources);

      this.showSemanticMemoryActions = false;
    },
    startsWithUnderscore(text) {
      const ignoreTags = ["chatid", "memory"];
      return text.startsWith("_") || ignoreTags.includes(text);
    },
    getTableDocumentName(document) {
      const documentName = document.sourceName || document.document_name;
      return documentName;
    },
    handleSearch: _.debounce(async function () {
      try {
        this.isLoading = true;
        if (this.search === "") {
          await this.$store.dispatch("GENAI_GET_UPLOADED_DOCUMENTS", { page: 1 });
          this.inSearchMode = false;
          this.isLoading = false;
          return;
        }
        await this.$store.dispatch("GENAI_SEARCH_DOCUMENTS", {
          search: this.search,
          documentType: "documents",
        });
        this.isLoading = false;
        this.inSearchMode = true;
      } catch (error) {
        this.inSearchMode = false;
        this.isLoading = false;
        this.$notify({
          title: "Error",
          message: "There was an error searching the documents",
          type: "error",
        });
      }
    }, 800),

    async handleClearSearch() {
      try {
        this.search = "";
        await this.$store.dispatch("GENAI_GET_UPLOADED_DOCUMENTS", { page: 1 });
        this.isLoading = false;
        this.inSearchMode = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          title: "Error",
          message: "There was an error clearing the search",
          type: "error",
        });
      }
    },

    renderedText(text) {
      return mdToHtml(text);
    },
    isAbleToEdit(document) {
      return (
        this.userProfile.email === document.uploadedBy ||
        this.getUserAuthentication["roles"].some((role) => this.allowEditRoles.includes(role))
      );
    },
    formattedDate(date) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },
    openTagEditor(document) {
      this.tagEditorVisible = true;
      this.selectedDocument = document;
    },
    updateTagEditorVisible(value) {
      this.tagEditorVisible = value;
    },
    async handleUpdateDocumentTag(data) {
      this.isLoadingEditTag = true;
      const { newDocument, oldDocument } = data;

      await this.$store.dispatch("GENAI_UPDATE_DOCUMENT", {
        document: newDocument,
      });

      this.$notify({
        title: "Success",
        message: "Document tags updated successfully",
        type: "success",
      });

      this.tagEditorVisible = false;
      await this.$store.dispatch("GENAI_GET_UPLOADED_DOCUMENTS", { page: this.currentPage });
      this.isLoadingEditTag = false;
    },
    openExportDialog() {
      this.exportDialogVisible = true;
    },
    async handleExport(selectedDocuments) {
      this.isLoadingExportImport = true;
      const blob = new Blob(
        [
          JSON.stringify({
            export_type: "documents",
            documents: selectedDocuments,
          }),
        ],
        { type: "application/json" }
      );
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "documents.json";
      link.click();
      URL.revokeObjectURL(url);
      this.isLoadingExportImport = false;
      this.exportDialogVisible = false;
    },
    updateExportDialogVisible(value) {
      this.exportDialogVisible = value;
    },
    openImportDialog() {
      this.importDialogVisible = true;
    },
    handleImport(selectedDocuments) {
      this.isLoadingExportImport = true;
      const uploadedBy = this.userProfile.email;
      this.$store
        .dispatch("GENAI_IMPORT_DOCUMENTS", { documents: selectedDocuments, uploadedBy })
        .then((result) => {
          if (result.success) {
            this.$notify({
              title: "Success",
              message: "Documents imported successfully",
              type: "success",
            });
          } else {
            this.$notify({
              title: "Error",
              message: "There was an error importing the documents",
              type: "error",
            });
          }
          this.isLoadingExportImport = false;
          this.importDialogVisible = false;
        });
    },
    updateImportDialogVisible(value) {
      this.importDialogVisible = value;
    },
  },
  async created() {
    const page = 1;
    await this.$store.dispatch("GENAI_GET_UPLOADED_DOCUMENTS", { page });
  },
};
</script>

<style lang="scss">
$kr-primary-color: #1d57d8;

.main-title {
  font-size: 36px;
  font-weight: 600;
  flex: 1;
  margin: 0;
}
#knowledge-base-wrapper {
  pre {
    font-family: monospace;
    white-space: pre-wrap; /* Preserve line breaks */
    line-height: 24px;
  }
  margin-top: 20px;

  #kb-document-search {
    width: fit-content;
    border-bottom: 1px solid #ddd;

    input {
      padding: 10px;
      outline: none;
      border: 0;
      transition: all 0.3s ease-in-out;
    }

    input::placeholder {
      color: #a6a9ad;
    }

    i {
      font-size: 14px;
    }
  }

  #kb-header {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .buttons {
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-left: auto;

      .el-button {
        margin-left: 10px;
      }
    }
  }

  #kb-body {
    margin-top: 20px;
    .kb-query-result {
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 20px;
    }

    .kb-documents {
      .documents-wrapper {
        .document {
          border: 1px solid #ddd;
          border-radius: 10px;
          margin-bottom: 20px;

          .main-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            transition: all 0.3s;

            .left-container {
              flex: 1;
              display: flex;
              height: 100%;

              .folder {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                margin-right: 20px;
                border-right: 1px solid #ddd;

                i {
                  font-size: 24px;
                  color: #a6a9ad;
                }
              }

              .detail {
                line-height: 30px;
                width: 100%;
                .title {
                  font-size: 18px;
                  font-weight: 600;
                  margin: 0;
                  max-width: 60%;
                  word-break: break-word;
                }

                .url {
                  a {
                    color: $kr-primary-color;
                  }
                }
              }
            }
          }

          .expand-content {
            border-top: 1px solid #ddd;
            padding: 20px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .empty {
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}

.kb-empty {
  min-height: 500px;
  display: flex;
  margin-top: 20px;
  height: 80vh;

  .kb-empty-wrapper {
    display: flex;
    border: 1px solid #ddd;
    width: 100%;
  }

  .filter-box {
    font-size: 20px;
    padding: 20px;
    justify-self: center;
    border-right: 1px solid #ddd;
    width: 20%;
    overflow: auto;

    p {
      margin: 25px 0;
    }

    .filter-select-box {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
  }

  .ask-box {
    font-size: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;

    p {
      margin: 25px 0;
    }

    .ask-box-body {
      flex: 1;
      border-bottom: 1px solid #ddd;
      padding: 10px;
      box-sizing: border-box;
    }

    .ask-box-footer {
      height: 10%;
      .el-input {
        flex: 1;
      }
    }
  }
}
.circular {
  background: none !important;
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.user-message,
.answer-message {
  width: 100%; /* Ensure messages take full width */
}
</style>
