import { Notifications, NotificationItemsContent } from "@uuip/unified-ui-platform-sdk";

// Mock Desktop for environments where Webex CC SDK is not available
const MockDesktop = {
  actions: {
    fireGeneralAutoDismissNotification: async () => {
      console.log("Mock Webex CC Notification Triggered");
      return ["added", "mock-reason", "mock-mode"];
    },
  },
};

let WebexDesktop: typeof MockDesktop | any = MockDesktop;

// Dynamically import Webex Desktop SDK only if AGENTX_SERVICE exists
(async function loadWebexDesktop() {
  if (typeof window !== "undefined" && (window as any).AGENTX_SERVICE) {
    try {
      const { Desktop } = await import("@wxcc-desktop/sdk");
      WebexDesktop = Desktop;
    } catch (error) {
      console.warn("Failed to load Webex SDK, using mock instead.", error);
    }
  }
})();

/**
 * Sends a push notification via Webex CC.
 * @param title The notification title.
 * @param message The notification message.
 * @returns The notification status or "unknown" if the operation fails.
 */
export async function sendWebexPushNotification(
  title: string,
  message: string
): Promise<Notifications.ItemMeta.Status | "unknown"> {
  const raw: Notifications.ItemMeta.Raw & {
    data: { mode: Notifications.ItemMeta.Mode.AutoDismiss };
  } = {
    data: {
      type: Notifications.ItemMeta.Type.Info,
      mode: Notifications.ItemMeta.Mode.AutoDismiss,
      title: title,
      data: new NotificationItemsContent.DataController({
        text: message,
        iconDetail: {
          iconName: "icon-alert-active_16",
          color: "blue",
          iconColor: "#08599C",
        },
      }),
    },
  };

  try {
    const result = await WebexDesktop.actions.fireGeneralAutoDismissNotification(raw);
    if (result) {
      const [status] = result;
      return status as Notifications.ItemMeta.Status;
    }
  } catch (error) {
    console.error("Error sending Webex notification:", error);
  }

  return "unknown";
}
